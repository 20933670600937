.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  overflow: overlay;
}

.App-link {
  color: rgb(112, 76, 182);
}
