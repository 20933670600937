a {
  text-decoration: none;
  color: inherit;
  padding: 1.5rem 1rem;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.nav__logo {
  position: absolute;
  height: 64px;
  left: calc(50% - 67px);
}
