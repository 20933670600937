.filter {
  width: 100%;
  margin-top: 4.25rem;
  padding: 1rem 0.5rem;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 10px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);

  &__box {
    margin-bottom: 1rem;
  }
  &__legend {
    text-align: left;
    margin-bottom: 0.2rem;
    font-weight: bold !important;
  }
  &__show-more-button {
    font-size: 0.8rem !important;
    padding: 0 !important;
    color: grey !important;
  }
}
