.table-container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 1rem;
}
.table__content {
  width: 100%;
}
@media (min-width: 960px) {
  .table__content {
    width: 83%;
  }
}
