.select-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.6rem;
  gap: 1rem;
}

@mixin component-tittle($content) {
  left: 10px;
  top: -10px;
  padding: 0 5px;
  color: rgb(127, 127, 127);
  content: $content;
  position: absolute;
  background-color: aliceblue;
}

.form {
  &__repetitiv-component-box {
    width: 100%;
    margin: 0.6rem auto;
    border: 1px solid hsl(0, 0%, 80%);
    padding: 1rem 1rem;
    border-radius: 4px;
    position: relative;
    &--team::after {
      @include component-tittle("Zespół");
    }
    &--history::after {
      @include component-tittle("Historia zmian");
    }
    &--screens::after {
      @include component-tittle("Zrzuty ekranu");
    }
  }
  &__file-input {
    padding: 6px 12px;
    margin-top: 2rem;
    border: 1px solid #ccc;
    display: inline-block;
    cursor: pointer;
  }
  &__screens-box {
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    div {
      width: 90%;
      margin: 1% 2%;
      position: relative;
    }
    @media (min-width: 800px) {
      div {
        width: 45%;
      }
    }
    img {
      left: 0;
      @supports (max-width: -moz-available) {
        max-width: -moz-available;
      }
      @supports (max-width: -webkit-fill-available) {
        max-width: -webkit-fill-available;
      }
    }
  }
  &--screens::after {
    @include component-tittle("Zrzuty ekranu");
  }
  &__current-screens-box::after {
    content: "";
    position: absolute;
    height: 1px;
    width: 90%;
    left: 5%;
    background-color: hsl(0, 0%, 80%);
    transform: translateY(15px);
  }
}

input[type="file"] {
  display: none;
}

.required {
  position: relative;
  &::after {
    content: "*";
    position: absolute;
    left: -14px;
    top: -10px;
    font-size: 1.5rem;
    font-family: monospace;
  }
}
